import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BidOptimizationGroupCreateDto, BidOptimizationGroupStatus } from '../../types/bid-optimization-group';
import { Campaign } from '../../types/campaign';
import CampaignsGridForDialog from '../budget/campaigns-grid-for-dialog';
import useAmazonApi from '../../hooks/use-amazon-api';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

interface CreateBidGroupDialogProps {
  toggle: () => void;
  isShowing: boolean;
  dynamicKey: number;
  handleCreateBidGroup: (bidOptimizationGroupCreateDto: BidOptimizationGroupCreateDto) => Promise<void>;
  refreshEntities: () => void;
  isCampaignsGrid?: boolean;
}

export const CreateBidGroupDialog = (props: CreateBidGroupDialogProps) => {
  const { toggle, isShowing, dynamicKey, handleCreateBidGroup, refreshEntities, isCampaignsGrid } = props;

  const { getCampaignsWithoutBidGroup } = useAmazonApi();
  const [bidGroupName, setBidGroupName] = useState<string>('');
  const [selectedCampaignData, setSelectedCampaignData] = useState<Partial<Campaign>[]>([]);
  const [acosTarget, setAcosTarget] = useState<number | null>(null);
  const [maxTargetBidAdjustmentPercentage, setMaxTargetBidAdjustmentPercentage] = useState<number>(15);
  const [maxPlacementAdjustmentPercentage, setMaxPlacementBidAdjustmentPercentage] = useState<number>(15);
  const [placementAcosThresholdPercentage, setPlacementAcosThresholdPercentage] = useState<number>(25);
  const [searchParams] = useSearchParams();
  const [backDropOpen, setBackDropOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const profileId = searchParams.get('profileId');

  const isInvalidAcosTarget = useMemo(() => {
    return acosTarget !== null && (acosTarget < 0 || acosTarget > 100);
  }, [acosTarget]);

  const handleCreateButtonClick = async () => {
    if (!profileId) {
      enqueueSnackbar('No profile ID detected.', { variant: 'error' });
      return;
    }

    if (!acosTarget || isInvalidAcosTarget) {
      enqueueSnackbar('Please enter a valid ACoS target.', { variant: 'error' });
      setStep(1);
      return;
    }

    setBackDropOpen(true);

    await handleCreateBidGroup({
      name: bidGroupName,
      profileId: profileId,
      status: BidOptimizationGroupStatus.Enabled,
      acosTarget: acosTarget,
      maximumTargetBidAdjustmentPercentage: maxTargetBidAdjustmentPercentage,
      maximumPlacementAdjustmentPercentage: maxPlacementAdjustmentPercentage,
      placementAcosThresholdPercentage: placementAcosThresholdPercentage,
      campaignIds: selectedCampaignData
        .filter((campaign) => campaign.campaignId !== undefined)
        .map((campaign) => campaign.campaignId!)
    });

    setBackDropOpen(false);
    toggle();
    refreshEntities();
  };

  const handleAddCampaigns = () => {
    setStep(step + 1);
  };

  const renderDialogActionButtons = () => {
    return (
      <>
        {step !== 1 && (
          <Button onClick={() => setStep(step - 1)} variant="text">
            <ArrowBackIcon />
          </Button>
        )}
        <Button onClick={toggle} variant="outlined">
          Cancel
        </Button>
        {step === 1 && !isCampaignsGrid && (
          <Button
            onClick={() => setStep(step + 1)}
            variant="contained"
            disabled={!bidGroupName || !acosTarget || isInvalidAcosTarget}
          >
            Next
          </Button>
        )}
        {step === 3 ||
          (step === 1 && isCampaignsGrid && (
            <Button onClick={handleCreateButtonClick} variant="contained" color="primary">
              Create
            </Button>
          ))}
      </>
    );
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Name your bid group and set your target ACoS percentage.
            </Typography>
            <Stack mt={4} mb={4} spacing={2}>
              <TextField
                label="Bid Group Name"
                InputLabelProps={{ shrink: true }}
                name="bidGroupName"
                value={bidGroupName}
                placeholder="Bid Group 1"
                size="medium"
                FormHelperTextProps={{ sx: { visibility: 'hidden' } }}
                onChange={(event) => setBidGroupName(event.target.value)}
              />
              <TextField
                label="Target ACoS Percentage"
                InputLabelProps={{ shrink: true }}
                name="acosTarget"
                value={acosTarget}
                placeholder="30"
                size="medium"
                type="number"
                InputProps={{
                  inputProps: { min: 1 },
                  endAdornment: (
                    <Typography color={'text.secondary'} variant="body1">
                      %
                    </Typography>
                  )
                }}
                sx={{ width: '100%' }}
                error={isInvalidAcosTarget}
                helperText={isInvalidAcosTarget ? 'Enter a valid ACoS target' : null}
                onChange={(event) => setAcosTarget(isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value))}
              />
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  label="Max Keyword Bid Adjustment Percentage"
                  InputLabelProps={{ shrink: true }}
                  name="maxTargetBidAdjustmentPercentage"
                  value={maxTargetBidAdjustmentPercentage}
                  placeholder="15"
                  size="medium"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <Typography color={'text.secondary'} variant="body1">
                        %
                      </Typography>
                    )
                  }}
                  sx={{ width: '100%' }}
                  onChange={(event) =>
                    setMaxTargetBidAdjustmentPercentage(isNaN(parseInt(event.target.value)) ? 15 : parseInt(event.target.value))
                  }
                />
                <Tooltip
                  title="This setting limits how much a keyword or product target's bid can be adjusted during a single optimization run.
                  Example: If the current bid is $2.00 and the Max Adjustment is set to 50%, the bid can only increase to a maximum of $3.00 or decrease to a minimum of $1.00."
                >
                  <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  label="Max Placement Bid Adjustment Percentage"
                  InputLabelProps={{ shrink: true }}
                  name="maxPlacementAdjustmentPercentage"
                  value={maxPlacementAdjustmentPercentage}
                  placeholder="15"
                  size="medium"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <Typography color={'text.secondary'} variant="body1">
                        %
                      </Typography>
                    )
                  }}
                  sx={{ width: '100%' }}
                  onChange={(event) =>
                    setMaxPlacementBidAdjustmentPercentage(
                      isNaN(parseInt(event.target.value)) ? 15 : parseInt(event.target.value)
                    )
                  }
                />
                <Tooltip
                  title="This setting prevents placement bids from being adjusted by more than the specified amount in any single optimization run.
                  Example: If the current placement is 30% and the Max Adjustment is 15%, the placement can increase to a maximum of 45% or decrease to a minimum of 15% in a single run."
                >
                  <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  label="Placement ACoS Threshold Percentage"
                  InputLabelProps={{ shrink: true }}
                  name="placementAcosThresholdPercentage"
                  value={placementAcosThresholdPercentage}
                  placeholder="25"
                  size="medium"
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <Typography color={'text.secondary'} variant="body1">
                        %
                      </Typography>
                    )
                  }}
                  sx={{ width: '100%' }}
                  onChange={(event) =>
                    setPlacementAcosThresholdPercentage(isNaN(parseInt(event.target.value)) ? 25 : parseInt(event.target.value))
                  }
                />
                <Tooltip
                  title="This setting limits placement changes to only those where the ACoS is either above the Target ACoS plus the Threshold or below the Target ACoS minus the Threshold.
                  Example: If the Target ACoS is 30 and the Threshold is 15, only placements with an ACoS above 45 or below 15 will be adjusted."
                >
                  <HelpOutlineIcon sx={{ cursor: 'pointer' }} />
                </Tooltip>
              </Stack>
            </Stack>
            <DialogActions>{renderDialogActionButtons()}</DialogActions>
          </>
        );
      case 2:
        return (
          <>
            <Stack height={'100%'}>
              <Typography variant="body1" gutterBottom>
                Select the campaigns you want, and click "Add Campaigns To Bid Group" to proceed.
              </Typography>
              <Stack spacing={2} sx={{ mt: 4 }}>
                <Box height={500}>
                  <CampaignsGridForDialog
                    getCampaigns={getCampaignsWithoutBidGroup}
                    handleAddCampaigns={handleAddCampaigns}
                    setSelectedCampaignData={setSelectedCampaignData}
                    isBidGroup
                    gridHeight={500}
                  />
                </Box>
                <DialogActions>{renderDialogActionButtons()}</DialogActions>
              </Stack>
            </Stack>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              Please confirm the details below before creating your bid group.
            </Typography>
            <Box mt={4}>
              <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                Bid Group Name:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {bidGroupName}
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                Target ACoS Percentage:
              </Typography>
              <Typography variant="body1" gutterBottom>
                {acosTarget}%
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={'bold'}>
                Campaigns:
              </Typography>
              {selectedCampaignData.map((campaign) => (
                <Typography variant="body1" gutterBottom component="li">
                  {campaign.name}
                </Typography>
              ))}
            </Box>
            <DialogActions>
              {renderDialogActionButtons()}
              <Button onClick={handleCreateButtonClick} variant="contained" color="primary">
                Create
              </Button>
            </DialogActions>
          </>
        );
      default:
        return (
          <>
            <Typography variant="body1" gutterBottom>
              You shouldn't be seeing this page. Please message the <strong>App Dev - Vector</strong> channel in Teams.
            </Typography>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ?autoplay=1&mute=1"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </>
        );
    }
  };

  return (
    <Dialog onClose={toggle} open={isShowing} fullWidth={step !== 1} maxWidth="lg" sx={{ transition: 'height width 1s' }}>
      <DialogTitle>Create Bid Group</DialogTitle>
      <Divider />
      <DialogContent key={dynamicKey}>{renderStepContent()}</DialogContent>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default CreateBidGroupDialog;
