import AmazonCampaignTypePicker from '../../amazon/pages/campaigns/campaign-builder/campaign-type-picker';
import SponsoredProductsCampaignBuilder from '../../amazon/pages/campaigns/campaign-builder/create-sponsored-products';
import AmazonCampaignOverview from '../../amazon/pages/campaigns/campaign-overview/campaign-overview';
import AmazonCampaigns from '../../amazon/pages/campaigns/campaigns';
import AmazonProducts from '../../amazon/pages/products/products';
import AmazonProfiles from '../../amazon/pages/profiles/profiles';
import AmazonProductTargets from '../../amazon/pages/targets/targets';
import SamsClubAds from '../../sams-club/pages/ads/ads';
import { SamsClubCreateAds } from '../../sams-club/pages/ads/create-ads';
import SamsClubBudgets from '../../sams-club/pages/budget/budgets';
import SamsClubCampaigns from '../../sams-club/pages/campaigns/campaigns';
import { SamsClubCreateCampaign } from '../../sams-club/pages/campaigns/create-campaign';
import SamsClubKeywords from '../../sams-club/pages/keywords/keywords';
import SamsClubNegativeKeywords from '../../sams-club/pages/keywords/negative-keywords';
import SamsClubProfiles from '../../sams-club/pages/profiles/profiles';
import SamsClubReview from '../../sams-club/pages/review/review';
import { VideoMedia } from '../components/sponsored-video-media/media/media';
import Ads from '../../walmart/pages/ads/ads';
import { CreateAds } from '../../walmart/pages/ads/create-ads';
import Budgets from '../../walmart/pages/budget/budgets';
import AmazonBudgets from '../../amazon/pages/budget/budgets';
import WalmartCampaigns from '../../walmart/pages/campaigns/campaigns';
import { CreateCampaign } from '../../walmart/pages/campaigns/create-campaign';
import KeywordHarvesting from '../../walmart/pages/keyword-harvesting/keyword-harvesting';
import Keywords from '../../walmart/pages/keywords/keywords';
import WalmartProfiles from '../../walmart/pages/profiles/profiles';
import WalmartReview from '../components/sponsored-video-media/media/review/review';
import { SponsoredVideoReview } from '../components/sponsored-video-media/media/review/sponsored-video-review/sponsored-video-review';
import { SearchInsights } from '../../walmart/pages/search-insights/search-insights';
import CitrusAdProfiles from '../../citrusad/pages/profiles/profiles';
import CitrusAdWallet from '../../citrusad/pages/wallet/wallet';
import { CitrusAdCreateCampaign } from '../../citrusad/pages/campaigns/create-campaign';
import CitrusAdCampaign from '../../citrusad/pages/campaigns/campaigns';
import CitrusAdCampaignOverview from '../../citrusad/pages/campaigns/campaign-overview/campaign-overview';
import AutomatedActions from '../../amazon/pages/automated-actions/automated-actions';
import AuditLogs from '../../amazon/pages/audit-logs/audit-logs';
import AdGroupOverview from '../../amazon/pages/campaigns/campaign-overview/ad-group-overview';
import ProfileDashboard from '../../amazon/pages/dashboard/dashboard';
import BudgetGroupOverview from '../../amazon/pages/budget/budget-group-overview';
import BidOptimizationGroups from '../../amazon/pages/bid-groups/bid-groups';
import BidGroupOverview from '../../amazon/pages/bid-groups/bid-group-overview';

export const deprecatedRoutes = [
  {
    key: 'deprecated-home',
    path: '/',
    element: <WalmartProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'deprecated-profiles',
    path: '/profiles',
    element: <WalmartProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'deprecated-campaigns',
    path: '/campaigns',
    element: <WalmartCampaigns />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'deprecated-campaigns-create',
    path: '/campaigns/create',
    element: <CreateCampaign />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'deprecated-ads',
    path: '/ads',
    element: <Ads />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'deprecated-ads-create',
    path: '/ads/create',
    element: <CreateAds />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'deprecated-keywords',
    path: '/keywords',
    element: <Keywords />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'deprecated-keyword-harvesting',
    path: '/keyword-harvesting',
    element: <KeywordHarvesting />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'deprecated-budgets',
    path: '/budgets',
    element: <Budgets />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'deprecated-review',
    path: '/review',
    element: <WalmartReview />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  }
];

export const walmartRoutes = [
  {
    key: 'walmart',
    path: '/walmart',
    element: <WalmartProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'walmart-profiles',
    path: '/walmart/profiles',
    element: <WalmartProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'walmart-campaigns',
    path: '/walmart/campaigns',
    element: <WalmartCampaigns />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'walmart-campaigns-create',
    path: '/walmart/campaigns/create',
    element: <CreateCampaign />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'walmart-ads',
    path: '/walmart/ads',
    element: <Ads />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'walmart-ads-create',
    path: '/walmart/ads/create',
    element: <CreateAds />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'walmart-keywords',
    path: '/walmart/keywords',
    element: <Keywords />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'walmart-keyword-harvesting',
    path: '/walmart/keyword-harvesting',
    element: <KeywordHarvesting />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'walmart-budgets',
    path: '/walmart/budgets',
    element: <Budgets />,
    allowedQueryParams: ['profileId', 'currentTab']
  },
  {
    key: 'walmart-review',
    path: '/walmart/review',
    element: <WalmartReview />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'walmart-sponsored-video-review',
    path: '/walmart/sponsored-video-review',
    element: <SponsoredVideoReview />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'walmart-media',
    path: '/walmart/media',
    element: <VideoMedia />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'walmart-search-insights',
    path: '/walmart/search-insights',
    element: <SearchInsights />,
    allowedQueryParams: []
  }
];

export const amazonRoutes = [
  {
    key: 'amazon',
    path: '/amazon',
    element: <AmazonProfiles />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-dashboard',
    path: '/amazon/dashboard',
    element: <ProfileDashboard />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'amazon-profiles',
    path: '/amazon/profiles',
    element: <AmazonProfiles />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-campaigns',
    path: '/amazon/campaigns',
    element: <AmazonCampaigns />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-campaigns-overview',
    path: '/amazon/campaigns/overview',
    element: <AmazonCampaignOverview />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-campaigns-overview-adGroup',
    path: '/amazon/campaigns/overview/adGroup',
    element: <AdGroupOverview />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-create-campaigns',
    path: '/amazon/campaigns/create',
    element: <AmazonCampaignTypePicker />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-campaign-builder-sponsored-products',
    path: '/amazon/campaigns/create/sponsored-products',
    element: <SponsoredProductsCampaignBuilder />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'amazon-targets',
    path: '/amazon/targets',
    element: <AmazonProductTargets />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'amazon-products',
    path: '/amazon/products',
    element: <AmazonProducts />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'amazon-automated-actions',
    path: '/amazon/automated-actions',
    element: <AutomatedActions />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'amazon-audit-logs',
    path: '/amazon/audit-logs',
    element: <AuditLogs />,
    allowedQueryParams: []
  },
  {
    key: 'amazon-budgets',
    path: '/amazon/budgets',
    element: <AmazonBudgets />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'amazon-budget-groups',
    path: '/amazon/budgetGroups',
    element: <BudgetGroupOverview />,
    allowedQueryParams: ['profileId', 'campaignBudgetGroupId']
  },
  {
    key: 'amazon-bid-optimization-groups',
    path: '/amazon/bid-optimization-groups',
    element: <BidOptimizationGroups />,
    allowedQueryParams: ['profileId', 'bidGroupId']
  },
  {
    key: 'amazon-bid-optimization-groups-overview',
    path: '/amazon/bid-optimization-groups/overview',
    element: <BidGroupOverview />,
    allowedQueryParams: ['profileId', 'bidGroupId']
  }
];

export const samsClubRoutes = [
  {
    key: 'sams-club',
    path: '/sams-club',
    element: <SamsClubProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'sams-club-profiles',
    path: '/sams-club/profiles',
    element: <SamsClubProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'sams-club-campaigns',
    path: '/sams-club/campaigns',
    element: <SamsClubCampaigns />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'sams-club-campaigns-create',
    path: '/sams-club/campaigns/create',
    element: <SamsClubCreateCampaign />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'sams-club-ads',
    path: '/sams-club/ads',
    element: <SamsClubAds />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'sams-club-ads-create',
    path: '/sams-club/ads/create',
    element: <SamsClubCreateAds />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'sams-club-keywords',
    path: '/sams-club/keywords',
    element: <SamsClubKeywords />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'sams-club-negative-keywords',
    path: '/sams-club/negative-keywords',
    element: <SamsClubNegativeKeywords />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'sams-club-review',
    path: '/sams-club/review',
    element: <SamsClubReview />,
    allowedQueryParams: ['profileId', 'campaignId', 'adGroupId']
  },
  {
    key: 'sams-club-sponsored-video-review',
    path: '/sams-club/sponsored-video-review',
    element: <SponsoredVideoReview />,
    allowedQueryParams: ['profileId', 'campaignId']
  },
  {
    key: 'sams-club-media',
    path: '/sams-club/media',
    element: <VideoMedia />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'sams-club-budgets',
    path: '/sams-club/budgets',
    element: <SamsClubBudgets />,
    allowedQueryParams: ['profileId']
  }
];

export const citrusAdRoutes = [
  {
    key: 'citrusAd',
    path: '/citrusad',
    element: <CitrusAdProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'citrusad-profiles',
    path: '/citrusad/profiles',
    element: <CitrusAdProfiles />,
    allowedQueryParams: ['']
  },
  {
    key: 'citrusad-wallets',
    path: '/citrusad/wallet',
    element: <CitrusAdWallet />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'citrusad-create-campaign',
    path: '/citrusad/campaigns/create',
    element: <CitrusAdCreateCampaign />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'citrusad-campaigns',
    path: '/citrusad/campaign',
    element: <CitrusAdCampaign />,
    allowedQueryParams: ['profileId']
  },
  {
    key: 'citrusad-campaigns-overview',
    path: '/citrusad/campaigns/overview',
    element: <CitrusAdCampaignOverview />,
    allowedQueryParams: ['profileId', 'campaignId']
  }
];

export const allRoutes = [...walmartRoutes, ...deprecatedRoutes, ...amazonRoutes, ...samsClubRoutes, ...citrusAdRoutes];
