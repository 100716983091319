import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useAdsApi from '../../shared/hooks/use-walmart-sams-club-api';
import { WalmartCampaignOption, WalmartCampaignOptions } from '../../shared/types/walmart-sams-club/campaign';
import { CampaignBrandTermTargeting } from '../../shared/types/walmart-sams-club/campaign-brand-term-targeting';

interface BrandTermTargetingDialogProps {
  isShowing: boolean;
  toggle: () => void;
  profileId: number;
  campaignId: number;
  campaignOptions: WalmartCampaignOptions;
}

const BrandTermTargetingDialog = (props: BrandTermTargetingDialogProps) => {
  const { isShowing, toggle, profileId, campaignId, campaignOptions } = props;
  const { getCampaign, updateCampaign } = useAdsApi();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [option, setOption] = useState<CampaignBrandTermTargeting>(CampaignBrandTermTargeting.OptIn);

  const handleSaveButtonClick = async () => {
    setIsSaveButtonDisabled(true);
    setIsLoading(true);

    let newCampaignOptions: WalmartCampaignOptions = [];

    if (Array.isArray(campaignOptions) && campaignOptions.length > 0) {
      newCampaignOptions = [...campaignOptions];
    }

    if (option === CampaignBrandTermTargeting.OptOut) {
      newCampaignOptions.push(WalmartCampaignOption.BrandTermOptOut);
    } else {
      newCampaignOptions.splice(newCampaignOptions.indexOf(WalmartCampaignOption.BrandTermOptOut), 1);
    }

    const response = await updateCampaign({
      campaignId: campaignId,
      campaignOptions: newCampaignOptions
    });

    if (response.success) {
      enqueueSnackbar('Campaign updated successfully', { variant: 'success' });
    } else {
      enqueueSnackbar(response.errorMessage, { variant: 'error' });
    }

    setIsLoading(false);
    setIsSaveButtonDisabled(false);

    toggle();
  };

  useEffect(() => {
    const fetchBrandTermTargetingOption = async () => {
      const response = await getCampaign(profileId, campaignId);

      if (response.success) {
        setOption(
          Array.isArray(response.body.campaignOptions) &&
            response.body.campaignOptions.includes(WalmartCampaignOption.BrandTermOptOut)
            ? CampaignBrandTermTargeting.OptOut
            : CampaignBrandTermTargeting.OptIn
        );
      } else {
        enqueueSnackbar('Error fetching brand term targeting option.', { variant: 'error' });
      }
    };

    fetchBrandTermTargetingOption();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog open={isShowing} onClose={toggle} maxWidth="sm" fullWidth>
      <DialogTitle>Brand Term Targeting</DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="body2">
          Choose whether to opt in or out. All new campaigns are opted in by default.
        </Typography>
        <Box mt={2} mb={2} width="50%">
          <Autocomplete
            id="brand-term-targeting-select"
            renderInput={(params) => <TextField {...params} size="medium" label="Brand Term Targeting" />}
            value={option}
            options={Object.values(CampaignBrandTermTargeting)}
            onChange={(_event: React.SyntheticEvent, value: NonNullable<CampaignBrandTermTargeting>) => {
              setOption(value);
            }}
            autoHighlight
            includeInputInList
            disableClearable
            disablePortal
          ></Autocomplete>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveButtonClick} disabled={isSaveButtonDisabled}>
          Save
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
};

export default BrandTermTargetingDialog;
