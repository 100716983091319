import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import Page from '../../../shared/components/page';
import useAdsApi from '../../../shared/hooks/use-walmart-sams-club-api';
import { Platform } from '../../../shared/types/platforms';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} excelOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

export const SearchInsights = () => {
  const { getSearchTrendsUrl } = useAdsApi(Platform.WALMART.value);

  const [searchTermReportUrl, setSearchTermReportUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchSearchTrendsUrl();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSearchTrendsUrl = async () => {
    setIsLoading(true);
    try {
      const response = await getSearchTrendsUrl();

      setSearchTermReportUrl(response.body);
    } catch (error) {
      console.error('Error fetching search trends url:', error);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Page title="Search Insights">
        <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Search Insights</Typography>
            <Box style={{ height: 'calc(100% - 100px)' }}>
              <Typography variant="body1">Loading...</Typography>
            </Box>
          </Stack>
        </Container>
      </Page>
    );
  } else {
    return (
      <Page title="Search Insights">
        <Container maxWidth="xl" style={{ height: '100%', marginTop: '10px' }}>
          <Stack spacing={1} style={{ height: '100%' }}>
            <Typography variant="h4">Search Insights</Typography>
            <Box style={{ height: 'calc(100% - 100px)' }}>
              <Typography variant="body1">Would you like to view the report in the grid or download the report?</Typography>
              <Button onClick={() => window.open(searchTermReportUrl)}>Download Report</Button>
            </Box>
          </Stack>
        </Container>
      </Page>
    );
  }
};
