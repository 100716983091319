import { Pageable, defaultPageable } from '../types/pageable';

export const buildPageableParams = (params: any, pageable: Pageable | null | undefined): string => {
  let result = '';

  result += serializeParams(params);
  result += serializePageableParams(!!pageable ? pageable : defaultPageable);

  return result;
};

export function serializePageableParams(params: Pageable): string {
  let result = '';

  if (params.limit) {
    result += `&limit=${params.limit}`;
  }

  if (params.offset) {
    result += `&offset=${params.offset}`;
  }

  if (params.filters) {
    params.filters?.map((filter) => {
      if (filter) {
        if (filter.comparator === 'isAnyOf' && Array.isArray(filter.value)) {
          filter.value = filter.value.join('|');
        }
        result += `&filters=${encodeURIComponent(JSON.stringify(filter))}`;
      }
    });
  }

  if (params.sorts) {
    params.sorts?.map((sort) => {
      if (sort) {
        result += `&sorts=${encodeURIComponent(JSON.stringify(sort))}`;
      }
    });
  }

  return result;
}

export const serializeParams = (params: any): string => {
  return Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : ''))
    .join('&');
};
