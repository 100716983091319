import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import PreviewIcon from '@mui/icons-material/Preview';
import EventIcon from '@mui/icons-material/Event';
import { hasValue } from '../../utilities/general-utilities';

export enum WalmartSamsClubCampaignType {
  SponsoredProducts = 'SponsoredProducts',
  Sba = 'Sba',
  Video = 'Video'
}

export const campaignTypes = [
  {
    label: 'Sponsored Products',
    value: 'sponsoredProducts'
  },
  {
    label: 'Search Brand Amplifier',
    value: 'sba'
  },
  {
    label: 'Sponsored Video',
    value: 'video'
  }
];

export const CampaignType = {
  SponsoredProducts: 'sponsoredProducts',
  Sba: 'sba',
  Video: 'video'
} as const;

export const campaignBuilderTargetingTypes = [
  {
    label: 'Automatic',
    value: 'auto'
  },
  {
    label: 'Manual',
    value: 'manual'
  }
];

export enum WalmartTargetingType {
  Auto = 'Auto',
  Manual = 'Manual'
}

export const budgetTypes = [
  {
    label: 'Daily',
    value: 'daily'
  },
  {
    label: 'Total',
    value: 'total'
  },
  {
    label: 'Both',
    value: 'both'
  }
];

export const campaignStatusOptions = [
  {
    label: 'Live',
    value: 'live',
    icon: PlayArrowIcon
  },
  {
    label: 'Paused',
    value: 'paused',
    icon: PauseIcon
  },
  {
    label: 'Completed',
    value: 'completed',
    icon: CheckIcon
  },
  {
    label: 'Proposal',
    value: 'proposal',
    icon: PreviewIcon
  },
  {
    label: 'Scheduled',
    value: 'scheduled',
    icon: EventIcon
  },
  {
    label: 'Enabled',
    value: 'enabled',
    icon: PlayArrowIcon
  }
];

export interface WalmartBiddingStrategy {
  strategy: WalmartBiddingStrategyStrategy;
}

export enum WalmartBiddingStrategyStrategy {
  Fixed = 'FIXED',
  Dynamic = 'DYNAMIC'
}

export const keywordMatchTypes = [
  {
    label: 'Exact',
    value: 'exact'
  },
  {
    label: 'Broad',
    value: 'broad'
  },
  {
    label: 'Phrase',
    value: 'phrase'
  }
];

export enum WalmartCampaignOption {
  BrandTermOptOut = 'BRAND_TERM_OPT_OUT'
}

export type WalmartCampaignOptions = WalmartCampaignOption[] | null;

export interface Campaign {
  name: string;
  campaignType: string;
  targetingType: string;
  status?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  totalBudget?: number;
  dailyBudget?: number;
  budgetType: string;
  rollover?: boolean;
  biddingStrategy?: WalmartBiddingStrategy;
  campaignOptions: WalmartCampaignOptions;
}

export interface UpdateCampaignRequest {
  campaignId: number;
  name?: string;
  isActiveInVector?: boolean;
  startDate?: string | undefined;
  endDate?: string | undefined;
  totalBudget?: number;
  dailyBudget?: number;
  budgetType?: string;
  biddingStrategy?: WalmartBiddingStrategy;
  campaignOptions?: WalmartCampaignOptions;
}

export const getUpdateCampaignRequest = (newRow: any, oldRow: any): UpdateCampaignRequest | null => {
  const campaign = getUpdatedCampaignFields(oldRow, newRow);

  // If there is only one key it is the campaignId and nothing has changed
  if (Object.keys(campaign).length === 1) {
    return null;
  }

  if (campaign?.startDate) {
    const startDate = new Date(campaign.startDate);

    campaign.startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
  }

  if (campaign?.endDate) {
    const endDate = new Date(campaign.endDate);

    campaign.endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
  }

  // When changing budget type Walmart requires a value for the budget type when changing it
  if (campaign?.budgetType) {
    if (campaign.budgetType === 'Total') {
      campaign.totalBudget = newRow?.totalBudget || 100;
    }

    if (campaign.budgetType === 'Daily') {
      campaign.dailyBudget = newRow?.dailyBudget || 50;
    }

    if (campaign.budgetType === 'Both') {
      campaign.dailyBudget = newRow?.dailyBudget || 50;
      campaign.totalBudget = newRow?.totalBudget || 100;
    }
  }

  // Only include fields that exist on the mutated object
  return {
    campaignId: campaign.campaignId,
    ...(hasValue(campaign?.name) && { name: campaign.name }),
    ...(hasValue(campaign?.startDate) && { startDate: campaign.startDate }),
    ...(hasValue(campaign?.endDate) && { endDate: campaign.endDate }),
    ...(hasValue(campaign?.totalBudget) && { totalBudget: campaign.totalBudget }),
    ...(hasValue(campaign?.dailyBudget) && { dailyBudget: campaign.dailyBudget }),
    ...(hasValue(campaign?.budgetType) && { budgetType: campaign.budgetType }),
    ...(hasValue(campaign?.biddingStrategy) && { biddingStrategy: campaign.biddingStrategy })
  };
};

const getUpdatedCampaignFields = (oldRow: any, newRow: any): UpdateCampaignRequest => {
  // Only include the fields on the object that have changed
  return {
    campaignId: oldRow.campaignId,
    ...(oldRow.name !== newRow.name && { name: newRow.name }),
    ...(oldRow.startDate !== newRow.startDate && { startDate: newRow.startDate }),
    ...(oldRow.endDate !== newRow.endDate && { endDate: newRow.endDate }),
    ...(oldRow.totalBudget !== newRow.totalBudget && { totalBudget: newRow.totalBudget }),
    ...(oldRow.dailyBudget !== newRow.dailyBudget && { dailyBudget: newRow.dailyBudget }),
    ...(oldRow.budgetType !== newRow.budgetType && { budgetType: newRow.budgetType })
  };
};

export const BiddingStrategyHelperTextDynamic =
  "Similar to 'up and down bidding' on Amazon, if the platform determines that a click is likely to lead to a conversion, it can raise the bid by up to 100%. The platform can also lower the bid by as much as 50% if the click is unlikely to lead to a conversion.";
