import { getGridSingleSelectOperators, getGridStringOperators, GridPreProcessEditCellProps } from '@mui/x-data-grid-premium';
import { dateColumnType } from '../../../../shared/components/grid-components/renderer/campaign/date-cell-components';
import {
  preProcessDailyBudgetCellProps,
  renderEditMonetaryCell
} from '../../../../shared/components/grid-components/renderer/campaign/renderMonetaryEditCell';
import { renderAsCurrencyFormatter } from '../../../../shared/components/grid-components/renderer/renderAsCurrencyFormat';
import { renderSyncStatus } from '../../../../shared/components/grid-components/renderer/renderSyncStatus';
import { ExtendedGridColDef } from '../../../../shared/types/grid-types';
import { Platform } from '../../../../shared/types/platforms';
import { METRIC_COLUMNS, MetricColumnField } from './metric-columns';
import { DEFAULT_STATE_OPTIONS } from '../../../types/state-options';
import { renderDynamicBidding } from '../renderer/dynamic-bidding/render-dynamic-bidding';
import { renderCampaignType } from '../renderer/renderCampaignType';
import { renderIsActiveInVectorToggle } from '../../../../shared/components/grid-components/renderer/campaign/renderIsActiveInVector';
import { renderNameWithActions } from '../renderer/renderNameWithActions';
import { renderState } from '../renderer/renderState';
import { renderTargetingType } from '../renderer/renderTargetingType';
import { renderCampaignPercentInBudget } from '../renderer/campaign-percent-in-budget-columns';
import { renderCampaignBudgetUsage } from '../renderer/campaign-budget-usage';
import { renderCampaignBudgetGroupName } from '../../../pages/budget/render-budget-group-name';
import { AmazonCampaignType } from '../../../types/campaign';

export const CAMPAIGN_COLUMNS: ExtendedGridColDef[] = [
  {
    field: 'active',
    headerName: 'Active',
    filterable: false,
    sortable: false,
    hideable: false,
    aggregable: false,
    width: 80,
    resizable: false,
    align: 'center',
    renderCell: (params) => renderIsActiveInVectorToggle(params)
  },
  {
    field: 'profileId',
    headerName: 'Profile ID',
    filterable: false,
    width: 100,
    type: 'string',
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'startsWith')
  },
  {
    field: 'campaignBudgetGroupId',
    headerName: 'Budget Group Id',
    width: 100,
    type: 'string'
  },
  {
    field: 'campaignBudgetGroupName',
    headerName: 'Budget Group Name',
    width: 100,
    renderCell: renderCampaignBudgetGroupName,
    type: 'string'
  },
  {
    field: 'campaignBidOptimizationGroupId',
    headerName: 'Bid Group Id',
    width: 100,
    minWidth: 80,
    type: 'string'
  },
  {
    field: 'campaignBidOptimizationGroupName',
    headerName: 'Bid Group Name',
    width: 100,
    minWidth: 80,
    type: 'string'
  },
  {
    field: 'campaignId',
    headerName: 'Campaign ID',
    filterable: true,
    width: 100,
    type: 'string',
    filterOperators: getGridStringOperators().filter((operator) => operator.value === 'equals')
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 300,
    editable: false,
    align: 'left',
    type: 'string',
    searchable: true,
    renderCell: renderNameWithActions(Platform.AMAZON.value)
  },
  {
    field: 'state',
    headerName: 'State',
    type: 'singleSelect',
    filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === 'not' || operator.value === 'is'),
    valueOptions: [...DEFAULT_STATE_OPTIONS.map((option) => option.label)],
    renderCell: renderState({ stateOptions: DEFAULT_STATE_OPTIONS }),
    width: 140,
    minWidth: 120
  },
  {
    field: 'syncState',
    headerName: 'Sync Status',
    width: 140,
    minWidth: 120,
    editable: false,
    type: 'singleSelect',
    align: 'left',
    valueOptions: ['Synced', 'Pending', 'Error'],
    renderCell: renderSyncStatus
  },
  {
    field: 'campaignType',
    headerName: 'Type',
    width: 100,
    minWidth: 60,
    editable: false,
    type: 'singleSelect',
    valueOptions: [
      AmazonCampaignType.SPONSORED_PRODUCTS,
      AmazonCampaignType.SPONSORED_BRANDS,
      AmazonCampaignType.SPONSORED_DISPLAY
    ],
    align: 'left',
    renderCell: renderCampaignType
  },
  {
    field: 'targetingType',
    headerName: 'Targeting Type',
    width: 160,
    minWidth: 90,
    type: 'singleSelect',
    valueOptions: ['Manual', 'Auto'],
    editable: false,
    align: 'left',
    renderCell: renderTargetingType
  },
  {
    field: 'dailyBudget',
    headerName: 'Daily Budget',
    width: 160,
    minWidth: 100,
    type: 'number',
    headerAlign: 'left',
    editable: true,
    cellClassName: () => {
      return 'numeric-edit-cell';
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => preProcessDailyBudgetCellProps(params, 1),
    renderEditCell: (params: any) => renderEditMonetaryCell(params),
    valueFormatter: renderAsCurrencyFormatter
  },
  {
    field: 'amazonSponsoredProductsDynamicBidding',
    headerName: 'Dynamic Bidding',
    filterable: false,
    width: 160,
    editable: false,
    align: 'left',
    renderCell: renderDynamicBidding
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    ...dateColumnType,
    width: 140,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    ...dateColumnType,
    width: 140,
    type: 'string',
    filterable: false,
    editable: true
  },
  {
    field: 'amazonSponsoredProductsPercentTimeInBudgetLastSevenDays',
    headerName: '% Time in Budget',
    description: `The percentage of time the campaign has been in budget for the past 7 days. This only applies to Sponsored Products campaigns.`,
    width: 200,
    type: 'number',
    editable: false,
    renderCell: (params) => renderCampaignPercentInBudget(params)
  },
  {
    field: 'budgetUsage',
    headerName: 'Budget Usage',
    description: `The percentage of the budget used for the campaign. Daily: The percentage of the daily budget used (Pulled within the last hour). Lifetime: The percentage of the lifetime budget used.`,
    width: 160,
    type: 'number',
    editable: false,
    filterable: false,
    sortable: false,
    renderCell: (params) => renderCampaignBudgetUsage(params)
  },
  ...METRIC_COLUMNS
];
