export interface BidOptimizationGroupDto {
  id: string;
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  maximumTargetBidAdjustmentPercentage: number | null;
  maximumPlacementAdjustmentPercentage: number | null;
  placementAcosThresholdPercentage: number | null;
  campaignIds?: string[];
}

export interface BidOptimizationGroupCreateDto {
  profileId: string;
  name: string;
  status: BidOptimizationGroupStatus;
  acosTarget: number;
  maximumTargetBidAdjustmentPercentage: number;
  maximumPlacementAdjustmentPercentage: number;
  placementAcosThresholdPercentage: number;
  campaignIds?: string[];
}

export enum BidOptimizationGroupStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled'
}

export interface AddCampaignsToBidGroupRequestDto {
  campaignBidOptimizationGroupId: string;
  campaignIds: string[];
}

export class BidGroupDefaultSettings {
  static maximumTargetBidAdjustmentPercentage = 15;
  static maximumPlacementAdjustmentPercentage = 15;
  static placementAcosThresholdPercentage = 25;
}
