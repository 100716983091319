import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import CampaignBuilderAddedKeywordsTable from '../../shared/components/campaign-builder-added-keywords-table';
import { CurrencyInput } from '../../shared/components/inputs/currency-input';
import { keywordMatchTypes } from '../../shared/types/walmart-sams-club/campaign';
import { AMAZON_TARGET_MINIMUM_BID } from '../types/target';
import CampaignBuilderSuggestedKeywords from './campaign-builder-keyword-suggestions';
import { UniversalKeyword, CampaignBuilderSuggestedKeyword } from '../types/keyword';

interface AddKeywordsProps {
  keywords: UniversalKeyword[];
  setKeywords: (keywords: UniversalKeyword[]) => void;
  minBid?: number;
  suggestedKeywords?: CampaignBuilderSuggestedKeyword[];
  suggestionSort: 'CONVERSIONS' | 'CLICKS';
  setSuggestionSort: (sort: 'CONVERSIONS' | 'CLICKS') => void;
  isLoading: boolean;
}

export default function AmazonCampaignBuilderAddKeywords(props: AddKeywordsProps) {
  const { keywords, setKeywords, minBid, suggestedKeywords, suggestionSort, setSuggestionSort, isLoading } = props;
  const [matchTypes, setMatchTypes] = useState(['exact']);
  const [userEnteredKeywords, setUserEnteredKeywords] = useState('');
  const [bid, setBid] = useState(minBid || AMAZON_TARGET_MINIMUM_BID);
  const [tabIndex, setTabIndex] = useState(0);
  const [bidType, setBidType] = useState('suggested' as 'suggested' | 'custom');

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const addKeywords = () => {
    const parsedKeywords = userEnteredKeywords
      .split('\n')
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword)
      .map((keyword) => keyword.toLowerCase());

    const objectifiedKeywords = matchTypes.flatMap((matchType) =>
      parsedKeywords.map((keyword: string) => ({
        keywordText: keyword,
        matchType: matchType,
        bid,
        id: `${keyword}-${matchType}`
      }))
    );

    const newKeywords = objectifiedKeywords.filter(
      (keyword: any) =>
        !keywords.some(
          (objKeyword: any) => objKeyword.keywordText === keyword.keywordText && objKeyword.matchType === keyword.matchType
        )
    );

    setUserEnteredKeywords('');
    setKeywords([...keywords, ...newKeywords]);
  };

  const addSuggestedKeyword = (keyword: UniversalKeyword) => {
    const newKeyword = {
      keywordText: keyword.keywordText,
      matchType: keyword.matchType,
      bid: bidType === 'suggested' ? keyword.bid : bid,
      id: `${keyword.keywordText}-${keyword.matchType}`
    };

    // check if keyword already exists
    if (keywords.some((objKeyword: any) => objKeyword.id === newKeyword.id)) {
      return;
    }

    setKeywords([...keywords, newKeyword]);
  };

  const handleMatchTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (matchTypes.includes(event.target.value)) {
      setMatchTypes(matchTypes.filter((matchType) => matchType !== event.target.value));
    } else {
      setMatchTypes([...matchTypes, event.target.value]);
    }
  };

  useEffect(() => {
    if (minBid) {
      setBid(minBid);
    }
  }, [minBid]);

  const isBidValid = () => {
    if (minBid) {
      return bid >= minBid;
    }

    return bid >= AMAZON_TARGET_MINIMUM_BID;
  };

  return (
    <Grid container direction="column" mt={2}>
      <Stack mb={2}>
        <Box sx={{ mb: 2 }}>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Suggested" />
            <Tab label="Enter List" />
          </Tabs>
        </Box>
        <Box display={'flex'} alignItems={'center'} sx={{ mb: 2 }}>
          <Typography fontWeight="bold" variant="body2" sx={{ mr: 2 }}>
            Bid:
          </Typography>
          {tabIndex === 0 ? (
            <FormControl sx={{ minWidth: 120, mr: 2 }} size="small">
              <Select value={bidType} onChange={(event) => setBidType(event.target.value as any)}>
                <MenuItem value={'suggested'}>Suggested</MenuItem>
                <MenuItem value={'custom'}>Custom</MenuItem>
              </Select>
            </FormControl>
          ) : null}
          {bidType !== 'suggested' || tabIndex === 1 ? (
            <CurrencyInput
              size="small"
              label="Bid"
              name="bid"
              error={!isBidValid()}
              sx={{ maxWidth: '100px' }}
              value={bid}
              onChange={(event) => setBid(Number(event.target.value))}
            />
          ) : null}
        </Box>
        <Box display={'flex'}>
          {keywordMatchTypes.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox checked={matchTypes.includes(option.value)} onChange={handleMatchTypeChange} value={option.value} />
              }
              label={option.label}
            />
          ))}
          <Box
            sx={{
              mr: 'auto'
            }}
          ></Box>
          <Button variant="outlined" onClick={() => setKeywords([])}>
            Remove All Keywords
          </Button>
        </Box>
        {tabIndex === 0 ? (
          <Box display={'flex'} alignItems={'center'} mt={2}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Sort By:
            </Typography>
            <FormControl size="small">
              <Select
                value={suggestionSort}
                onChange={(event) => setSuggestionSort(event.target.value as 'CONVERSIONS' | 'CLICKS')}
              >
                <MenuItem value={'CONVERSIONS'}>Conversions</MenuItem>
                <MenuItem value={'CLICKS'}>Clicks</MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : null}
      </Stack>
      <Grid item>
        <Grid container justifyContent={'space-between'} alignItems="center" spacing={1}>
          <Grid item xs={tabIndex === 1 ? 4 : 5}>
            {tabIndex === 1 ? (
              <TextField
                id="campaignItemIds"
                label="Keywords"
                onChange={(event) => {
                  setUserEnteredKeywords(event.target.value);
                }}
                placeholder="Enter one keyword per line"
                inputProps={{
                  style: { fontSize: '14px' }
                }}
                multiline
                value={userEnteredKeywords}
                sx={{ mt: 1, width: '100%' }}
                rows={15}
                fullWidth
              />
            ) : isLoading ? (
              <Box
                display={'flex'}
                alignItems={'center'}
                width="370px"
                height="370px"
                justifyContent={'center'}
                border={'1px solid #ccc'}
                borderRadius={1}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ width: '100%' }}>
                {suggestedKeywords && (
                  <CampaignBuilderSuggestedKeywords
                    keywords={suggestedKeywords}
                    selectedMatchTypes={matchTypes}
                    handleAddKeyword={addSuggestedKeyword}
                    bidType={bidType}
                  />
                )}
              </Box>
            )}
          </Grid>
          {tabIndex === 1 ? (
            <Grid item xs={1}>
              <Grid container direction="column" alignItems="center">
                <Button sx={{ my: 0.5 }} variant="outlined" size="small" aria-label="move all right" onClick={addKeywords}>
                  <Typography variant="h6" fontWeight={'bold'}>
                    ≫
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          ) : null}
          <Grid item xs={7}>
            <CampaignBuilderAddedKeywordsTable keywords={keywords} setKeywords={setKeywords} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
