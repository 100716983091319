import React from 'react';
import { Box, Skeleton, Button } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const revealAnimation = keyframes`
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const AnimatedPolyline = styled('polyline')(({ theme }) => ({
  strokeDasharray: 1000,
  strokeDashoffset: 1000,
  animation: `${revealAnimation} 2s ease-in-out infinite`,
  fill: 'none',
  stroke: theme.palette.divider,
  strokeWidth: 4,
  strokeLinecap: 'round'
}));

const ChartSkeletonLoader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: theme.palette.background.paper,
        borderRadius: '0.5rem',
        border: '1px solid',
        borderColor: 'divider',
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 1
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
          <Skeleton variant="text" width={100} height={30} />
        </Box>
        <Button variant="outlined" disabled>
          <Skeleton variant="circular" width={24} height={24} />
        </Button>
      </Box>

      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '300px',
          mb: 2,
          borderRadius: '0.5rem',
          overflow: 'hidden',
          bgcolor: theme.palette.background.paper
        }}
      >
        <Box
          width="100%"
          height="100%"
          sx={{
            background: theme.palette.action.hover
          }}
        />

        <Box
          component="svg"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '60%',
            width: '40%'
          }}
        >
          <AnimatedPolyline points="0,80 50,60 100,100 150,50 200,70 250,40 300,90 350,60 400,50" />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem' }}>
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton variant="text" width={80} height={20} />
        </Box>
        <Skeleton variant="rectangular" width={60} height={20} />
      </Box>
    </Box>
  );
};

export default ChartSkeletonLoader;
